<template>
  <div class="create-batch-check-order-job">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="OrderStatusFlow" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`支付信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="checkOrder"
            :text="$t('审核')"
            :params="{
              docType,
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="formData.status === 'WAIT_PAY'"
            class="ml10"
            api="payPaymentNote"
            :text="$t('付款')"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
          <FindRelatedDocBtn class="ml10" :id="id" :docType="docType" v-if="isDetail" />
          <OperateBtn
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            class="ml10"
            api="cancelPaymentNote"
            :text="$t('作废')"
            type="danger"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="
              isDetail &&
              !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status) &&
              !formData.voucherGenerated
            "
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('基本信息')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款单号`)">
                    <a-input disabled v-model="formData.paymentNoteSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :placeholder="$t(`单据类型`)"
                      :list="payment_note_type"
                      :code.sync="formData.orderType"
                      :disabled="isDisabled || isFromReceivable"
                      @change="changeOrderType"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <CommonPayeeSelect
                    :docType="docType"
                    :orderType="formData.orderType"
                    :type.sync="formData.payeeType"
                    :code.sync="formData.payeeCode"
                    :name.sync="formData.payeeName"
                    :disabled="isDisabled || isFromReceivable"
                    :businessUnitCode="formData.businessUnitCode"
                    @change="supplierIndexChange"
                    @input="changePayeeType"
                  />
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)" required>
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款金额本位币`)">
                    <PriceInput
                      disabled
                      :currency="formData.functionalCurrency"
                      :value="formData.functionalPaymentAmount"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款货币`)" required>
                    <SelectCurrency
                      :code.sync="formData.paymentCurrency"
                      :disabled="isDisabled"
                      @change="changeCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.paymentCurrency"
                      :value="formData.paymentAmount"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算方式`)" required>
                    <CommonQuerySelect
                      api="getSettlementMethodList"
                      :placeholder="$t(`结算方式`)"
                      :code.sync="formData.settlementMethod"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.remark"
                      :placeholder="$t(`备注`)"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input disabled v-model="paymentNoteStatusMapping[formData.status]"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`是否生成凭证`)">
                    <a-input
                      disabled
                      :value="+formData.voucherGenerated ? $t('是') : $t('否')"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`付款时间`)">
                    <a-input disabled :value="formData.payTime"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="AmountInfo" :title="$t('支付信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款账户`)">
                    <CommonSelect
                      :placeholder="$t(`付款账户`)"
                      :list="capitalAccountList"
                      :disabled="isDisabled"
                      :name.sync="formData.payerAccountName"
                      :code.sync="formData.payerAccountCode"
                      @change="changeCapitalAccount"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`银行账号`)">
                    <a-input
                      :disabled="true"
                      :value="formData.payerAccountCode"
                      :placeholder="$t(`银行账号`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款银行`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.payerBankName"
                      :placeholder="$t(`付款银行`)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款账号`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.payeeAccount"
                      :placeholder="$t(`收款账号`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款账号名称`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.payeeAccountName"
                      :placeholder="$t(`收款账号名称`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款账号开户行`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.payeeBankName"
                      :placeholder="$t(`收款账号开户行`)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
          </a-form-model>
          <ModalBox id="ProductInfo" :title="$t('明细')">
            <template v-slot:header>
              <div class="option-btns pr20" v-if="!isDisabled">
                <span class="text-link fz16" @click="addLineHandle">
                  {{ $t('增行') }}
                </span>
                <span class="text-link fz16 ml20" @click="deleteLineHandle">
                  {{ $t('删行') }}
                </span>
              </div>
            </template>
            <CommonTable
              :columns="columns"
              :dataSource="showTableList"
              :scroll="{ x: 1200 }"
              :showPagination="false"
              :showSelect="!isDisabled"
              :showSetting="false"
              :selectedRowKeys.sync="selectedRowKeys"
            >
              <template v-slot:no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:paymentPurpose="{ record }">
                <CommonSelect
                  :disabled="isDisabled"
                  :list="paymentPurposeList"
                  :code.sync="record.paymentPurpose"
                />
              </template>
              <template v-slot:payeeCode="{ record }">
                <CommonPayeeSelect
                  :hideType="true"
                  :orderType="formData.orderType"
                  :type.sync="record.payeeType"
                  :code.sync="record.payeeCode"
                  :name.sync="record.payeeName"
                  :disabled="isDisabled"
                  :businessUnitCode="record.businessUnitCode"
                />
              </template>
              <template v-slot:settlementCurrency="{ record }">
                <SelectCurrency
                  :disabled="isDisabled || !!record.referDocDetailId"
                  :code.sync="record.settlementCurrency"
                />
              </template>
              <template v-slot:payableAmount="{ record }">
                <PriceInput
                  :disabled="isDisabled"
                  :currency="record.settlementCurrency"
                  :value.sync="record.payableAmount"
                  @blur="() => handleChange(record)"
                />
              </template>
              <template v-slot:discountAmount="{ record }">
                <PriceInput
                  :currency="record.settlementCurrency"
                  :disabled="isDisabled"
                  :value.sync="record.discountAmount"
                  @blur="() => handleChange(record)"
                />
              </template>
              <template v-slot:paymentAmount="{ text }">
                <PriceInput
                  v-if="text"
                  :currency="formData.paymentCurrency"
                  :value="text"
                  :showTxt="true"
                />
              </template>
              <template v-slot:remark="{ record }">
                <a-input v-model="record.remark" :disabled="isDisabled"></a-input>
              </template>
            </CommonTable>
          </ModalBox>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal :title="$t('单据转换报告')" v-model="resultVisible" width="800px">
      <CommonTable
        :columns="resultColumns"
        :dataSource="resultList"
        :showPagination="false"
        :showSetting="false"
      >
        <template v-slot:no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:status="{ record }">
          <span class="text-success" v-if="record.success">
            {{ $t('成功') }}
          </span>
          <span class="text-error" v-else>
            {{ $t('失败') }}
          </span>
        </template>
      </CommonTable>
      <template slot="footer">
        <a-button @click="resultVisible = false">{{ $t('关闭') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectCurrency from '@component/selectCurrency'
import VoucherBtn from '@component/voucherBtn'
import { add, subtract, multiply } from '@/common/number'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import deepClone from '@/common/deepClone'
import { convertKeysToCamelCase, goBack } from '@/common'
import debounce from '@/common/debounce'
import OperationLog from '@component/operationLog'
import CommonPayeeSelect from '@component/commonPayeeSelect'

export default {
  name: 'paymentNoteDetail',
  components: {
    DatePicker,
    SelectCurrency,
    VoucherBtn,
    BottomBtns,
    OperationLog,
    CommonPayeeSelect,
  },
  data() {
    return {
      id: '',
      sn: '',
      docType: 'PaymentNote',
      loading: false,
      visible: false,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑
      saveLoading: false,
      draftLoading: false,
      tableData: [],
      selectedRowKeys: [],
      formData: {
        businessUnitCode: '',
        businessUnitName: '',
        payeeCode: '',
        payeeName: '',
        orderType: undefined,
        amountWithoutTax: undefined,
        payableAmount: undefined,
        paymentAmount: undefined,
        functionalPaymentAmount: undefined,
        functionalCurrency: 'CNY',
        settlementCurrency: 'CNY',
        paymentCurrency: 'CNY',
        status: 'DRAFT',
        payeeType: 'SUPPLIER',
        capitalAccountId: undefined,
        payerAccountCode: undefined,
        payerAccountName: undefined,
        payerBankName: undefined,
      },
      purchaseAllQuantity: 0,
      purchaseAllAmount: 0,
      totalPayableAmount: 0,
      resultVisible: false,
      resultList: [],
      isFromReceivable: false,
      capitalAccountList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['payment_note_type', 'payment_purpose', 'currencyRateMapping', 'contact_type']),
    ...mapGetters(['paymentNoteStatusMapping', 'currencySymbolMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    columns() {
      const nameMapping = {
        SUPPLIER: this.$t('供应商'),
        CUSTOMER: this.$t('客户'),
        STAFF: this.$t('人员'),
      }
      let list = [
        {
          title: this.$t('付款用途'),
          align: 'center',
          dataIndex: 'paymentPurpose',
          width: 120,
          scopedSlots: {
            customRender: 'paymentPurpose',
          },
        },
        {
          title: nameMapping[this.formData.payeeType],
          align: 'center',
          dataIndex: 'payeeCode',
          width: 120,
          scopedSlots: {
            customRender: 'payeeCode',
          },
        },
        {
          title: this.$t('结算货币'),
          align: 'center',
          dataIndex: 'settlementCurrency',
          width: 150,
          scopedSlots: {
            customRender: 'settlementCurrency',
          },
        },
        {
          title: this.$t('应付金额'),
          align: 'center',
          dataIndex: 'payableAmount',
          width: 150,
          scopedSlots: {
            customRender: 'payableAmount',
          },
        },
        {
          title: this.$t('折扣'),
          align: 'center',
          dataIndex: 'discountAmount',
          width: 120,
          scopedSlots: {
            customRender: 'discountAmount',
          },
        },
        {
          title: this.$t('实付金额'),
          align: 'center',
          dataIndex: 'paymentAmount',
          width: 120,
          scopedSlots: {
            customRender: 'paymentAmount',
          },
        },
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: {
            customRender: 'remark',
          },
        },
      ]
      return list
    },
    scrollHeight() {
      return window.innerHeight - 300 + 'px'
    },
    resultColumns() {
      return [
        {
          title: this.$t('源单数据'),
          dataIndex: 'orderSn',
        },
        {
          title: this.$t('下推状态'),
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: this.$t('消息'),
          dataIndex: 'desc',
        },
      ]
    },
    showTableList() {
      return this.tableData.filter((item) => item.showStatus !== -1)
    },
    currencyRate() {
      return this.currencyRateMapping[this.formData.paymentCurrency] || 1
    },
    paymentPurposeList() {
      if (!this.formData.orderType) return this.payment_purpose
      if (['PURCHASE_PAYMENT', 'PREPAYMENT'].includes(this.formData.orderType)) {
        return this.payment_purpose.filter((item) =>
          ['PURCHASE_PAYMENT', 'PREPAYMENT'].includes(item.code)
        )
      }
      if (this.formData.orderType === 'OTHER_PAYMENT') {
        return this.payment_purpose.filter((item) => ['OTHER_PAYMENT'].includes(item.code))
      }
      return this.payment_purpose.filter((item) =>
        ['SALE_REFUND', 'PREPAYMENT_REFUND'].includes(item.code)
      )
    },
  },
  methods: {
    init() {
      this.isCopy = false
      this.isEdit = false
      this.isDetail = false
      this.selectedRowKeys = []
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.isDetail = true
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      } else {
        Object.assign(this.$data, this.$options.data())
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        this.getCapitalAccountList()
      }
      this.$nextTick(() => {
        this.getDetailInfo()
      })
    },
    changeBU(value) {
      // console.log('changeBUValue: ', value)
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      if (!this.formData.functionalCurrency) {
        this.formData.functionalCurrency = value.currency
      }
      if (!this.formData.paymentCurrency) {
        this.formData.paymentCurrency = value.currency
      }
    },
    changeOrderType() {
      this.tableData.forEach((item) => {
        item.paymentPurpose = undefined
      })
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    supplierIndexChange(val) {
      if (!this.formData.businessUnitCode) {
        this.formData.businessUnitCode = val.business_unit_code
        this.formData.businessUnitName = val.business_unit_name
      }
      this.formData.payeeAccount = val.bank_account
      this.formData.payeeBankName = val.bank_name
    },
    async getCapitalAccountList() {
      let res = await http({
        url: api.getCapitalAccountList,
        type: 'post',
        data: {
          businessUnitCode: this.formData.businessUnitCode,
          // isDefaultPayAccount: true,
        },
      })
      const list = res.result.list
      let defaultPayItem = list.find((item) => item.is_default_pay_account) || {}
      this.capitalAccountList = list
      this.changeCapitalAccount(defaultPayItem)
    },
    async getDetailInfo() {
      this.loading = true
      let paymentNoteFormdata = localStorage.getItem('paymentNoteFormdata'),
        pushTableList = localStorage.getItem('pushTableList')
      if (this.id) {
        let res = await http({
          url: api.getPaymentNoteDetail,
          type: 'post',
          data: {
            id: this.id,
          },
        })
        let result = convertKeysToCamelCase(res.result)
        this.tableData = deepClone(result.detailList)
        if (this.tableData.length === 0) {
          this.addLineHandle()
        }
        let formData = result
        this.formData = formData
        this.calcAllAmount()
        if (result.status === 'DRAFT') {
          this.isEdit = true
        }
      } else if (paymentNoteFormdata) {
        //复制
        this.isCopy = true
        let data = JSON.parse(paymentNoteFormdata)
        // console.log(data)
        this.formData = data
        this.formData.paymentNoteSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        delete this.formData.tableData
        localStorage.removeItem('paymentNoteFormdata')
      } else if (pushTableList) {
        //应付单下推过来
        this.isCopy = true
        this.getFormData()
        let data = JSON.parse(pushTableList)
        this.tableData = deepClone(data)
        localStorage.removeItem('pushTableList')
        localStorage.removeItem('pushFormData')
        this.calcAllAmount()
      }
      this.loading = false
    },
    // 待核销/部分核销状态 planList里面status: "NORMAL"可以下推生成付款申请单、付款单
    getFormData() {
      let pushFormData = JSON.parse(localStorage.getItem('pushFormData'))
      // console.log('pushFormData: ', pushFormData)
      this.formData.payeeCode = pushFormData.payeeCode
      this.formData.payeeName = pushFormData.payeeName
      this.formData.businessUnitCode = pushFormData.businessUnitCode
      this.formData.businessUnitName = pushFormData.businessUnitName
      this.formData.functionalCurrency = pushFormData.functionalCurrency
      this.formData.paymentCurrency =
        pushFormData.paymentCurrency || pushFormData.functionalCurrency
      this.formData.settlementMethod = pushFormData.settlementMethod
      this.formData.payeeType = pushFormData.payeeType
      this.isFromReceivable = true
      const typeMap = {
        OTHER: 'OTHER_PAYMENT',
        SALE_REFUND: 'SALE_REFUND',
        OTHER_PAYMENT: 'OTHER_PAYMENT',
      }
      if (typeMap[pushFormData.orderType]) {
        this.formData.orderType = typeMap[pushFormData.orderType]
      } else {
        this.formData.orderType = 'PURCHASE_PAYMENT' //下推默认采购付款
      }
      if (pushFormData.docType === 'ReceivableOrder') {
        //应收单下推生成销售退款收款单
        this.formData.payeeType = 'CUSTOMER'
        this.formData.orderType = 'SALE_REFUND' //销售退款
      }
    },
    handleChange(record) {
      if (
        !this.$isEmpty(record.discountAmount) &&
        Math.abs(+record.discountAmount) > Math.abs(+record.payableAmount)
      ) {
        this.$message.warning(this.$t('折扣金额不能大于应付金额'))
        record.discountAmount = record.payableAmount
      }
      // this.$set(record, 'discountAmount', record.discountAmount)
      let paymentAmount = multiply(
        subtract(record.payableAmount, record.discountAmount || 0),
        this.currencyRate
      ).toFixed(4)
      this.$set(record, 'paymentAmount', Number(paymentAmount))
      this.calcAllAmount()
    },
    calcAllAmount() {
      let functionalPaymentAmount = 0,
        payableAmount = 0,
        paymentAmount = 0
      this.tableData.forEach((item) => {
        functionalPaymentAmount = add(
          functionalPaymentAmount,
          subtract(item.payableAmount, item.discountAmount) || 0
        )
        payableAmount = add(payableAmount, item.payableAmount || 0)
        paymentAmount = add(paymentAmount, item.paymentAmount || 0)
      })
      this.formData.functionalPaymentAmount = functionalPaymentAmount //付款金额本位币
      this.formData.paymentAmount = paymentAmount //付款金额
      this.formData.payableAmount = payableAmount //应付金额
    },
    verification() {
      this.tableData.forEach((res, index) => {
        if (!res.paymentPurpose) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`第X行请选择付款用途`, { X: index + 1 }),
          })
          throw Error()
        }
        if (!res.payeeCode) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`第X行请选择往来对象`, { X: index + 1 }),
          })
          throw Error()
        }
      })
      return Promise.resolve()
    },
    backForm(back = 0) {
      goBack('paymentNoteList', back)
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      let data = JSON.stringify(this.formData)
      localStorage.setItem('paymentNoteFormdata', data)
      this.$router.push('/payableManage/paymentNoteDetail')
    },
    chooseDueDate(record, data) {
      record.dueDate = data.data
    },
    onSubmit: debounce(async function (status) {
      if (status !== 'DRAFT') {
        if (!this.formData.settlementMethod) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`请选择结算方式`),
          })
          return
        }
        await this.verification()
      }
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = deepClone(this.tableData).map((item) => {
        item.paymentCurrency = this.formData.paymentCurrency
        return item
      })
      data.merchantCode = localStorage.getItem('merchantCode')
      await http({
        url: api.createPaymentNote,
        data: {
          paymentNoteReqVO: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    addLineHandle() {
      this.tableData.push({
        payableAmount: '',
        discountAmount: '',
        paymentAmount: '',
        settlementCurrency: this.formData.functionalCurrency,
        dueDate: moment().format('YYYY-MM-DD'),
        paymentPurpose: undefined,
        payeeType: this.formData.payeeType,
        payeeCode: this.formData.payeeCode,
        payeeName: this.formData.payeeName,
      })
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.tableData
        .filter((item) => item.showStatus !== -1)
        .forEach((item, index) => {
          if (this.selectedRowKeys.includes(index)) {
            this.$set(item, 'showStatus', -1)
            item.payableAmount = 0
            item.applyAmount = 0
            item.expectedPaymentAmount = 0
          }
        })
      this.selectedRowKeys = []
      this.calcAllAmount()
    },
    changeCurrency() {
      this.tableData.forEach((item) => {
        if (item.paymentAmount) {
          item.paymentAmount = Number(
            multiply(subtract(item.payableAmount, item.discountAmount), this.currencyRate).toFixed(
              4
            )
          )
        }
      })
      this.calcAllAmount()
    },
    changePayeeType() {
      this.formData.payeeCode = undefined
      this.formData.payeeName = undefined

      this.formData.payeeAccount = undefined
      this.formData.payeeAccountName = undefined
      this.formData.payeeBankName = undefined

      this.tableData.forEach((item) => {
        item.payeeType = this.formData.payeeType
        item.payeeCode = undefined
        item.payeeName = undefined
      })
    },
    changeCapitalAccount(value) {
      // console.log('value: ', value)
      this.formData.capitalAccountId = value.id
      this.formData.payerAccountCode = value.code
      this.formData.payerAccountName = value.name
      this.formData.payerBankName = value.bank_name
    },
  },
}
</script>

<style lang="scss" scoped>
.statusBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-table-heard:before {
  content: '*';
  color: red;
}

.form-content {
  padding: 20px 5%;
}
.ant-form-item {
  margin-bottom: 0;
}
.search-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;
  .add {
    position: absolute;
    display: block;
    right: -45px;
  }
}
.goods-list {
  flex-wrap: wrap;
  .goods-item {
    line-height: normal;
    margin-right: 6px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
}
.infoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fixContent {
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
}
.node-modal {
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}
.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.left-div {
  flex: 1;
}
.right-div {
  background-color: transparent;
  font-size: 12px;
  width: 300px;
  height: 591px;
  overflow: auto;
}
.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}
.modalA ::v-deep .ant-modal-footer {
  border-top: 0px solid #e8e8e8;
}
.modalBox {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.pricing-method {
  margin-bottom: 10px;
}
</style>
